<div class="w-full flex flex-row justify-between items-center content-center gap-2">
    <button mat-icon-button *ngIf="isSideMenu()" (click)="toggleSideMenu()">
        <mat-icon>menu</mat-icon>
    </button>
    @if(menu().length>0){
        <button mat-icon-button *ngIf="isMobile() && !isSideMenu()" [matMenuTriggerFor]="mainMenu">
            <mat-icon>menu</mat-icon>
        </button>
    }
    <mat-menu
        #mainMenu="matMenu"
        class="flex flex-col"
        xPosition="before">
        @for (item of menu(); track item) {
            <button mat-menu-item
                    (click)="navigateTo(item.page); triggerMenu()"
                    [class.active]="item.isHover || rla.isActive"
                    [routerLink]="item.url"
                    (mouseenter)="item.isHover = true"
                    (mouseleave)="item.isHover = false"
                    routerLinkActive
                    #rla="routerLinkActive">
                @if (item.icon) {
                    <mat-icon
                            [color]="rla.isActive || item.isHover ? '500' : undefined">{{item.icon}}</mat-icon>
                }
                <div [class.text-start]="isMobile()" class="two-lines-ellipsis word-break-word">
                    {{ item.tKey | translate }}
                </div>
            </button>
        }
    </mat-menu>
    <img src="../../../../assets/logos/Aktion_Lichtblicke_Claim_FINAL.jpg" (click)="goToStartPage()" class="header-logo">
    <div *ngIf="!isMobile() && !isSideMenu()" class="h-full flex flex-row justify-center content-center items-center gap-4">
        @for (item of menu(); track item) {
            <button mat-button
                    (click)="navigateTo(item.page); triggerMenu()"
                    [class.active]="item.isHover || rla.isActive"
                    [routerLink]="item.url"
                    (mouseenter)="item.isHover = true"
                    (mouseleave)="item.isHover = false"
                    routerLinkActive
                    #rla="routerLinkActive">
                @if (item.icon) {
                    <mat-icon
                            [color]="rla.isActive || item.isHover ? '500' : undefined">{{item.icon}}</mat-icon>
                }
                <div [class.text-start]="isMobile()" class="two-lines-ellipsis word-break-word">
                    {{ item.tKey | translate }}
                </div>
            </button>
        }
    </div>

    @if (profile) {
        @if(isMobile()){
            <button mat-icon-button
                    [class.active]="profile.isHover || rla.isActive"
                    (mouseenter)="profile.isHover = true"
                    (mouseleave)="profile.isHover = false"
                    routerLinkActive
                    #rla="routerLinkActive"
                    [matMenuTriggerFor]="userMenu">
                @if (profile.icon) {
                    <mat-icon
                            [color]="rla.isActive || profile.isHover ? '500' : undefined">{{profile.icon}}</mat-icon>
                }
            </button>
        } @else {
            <button mat-button
                    [class.active]="profile.isHover || rla.isActive"
                    (mouseenter)="profile.isHover = true"
                    (mouseleave)="profile.isHover = false"
                    routerLinkActive
                    #rla="routerLinkActive"
                    [matMenuTriggerFor]="userMenu">
                <div *ngIf="!isMobile()" class="two-lines-ellipsis word-break-word">
                    {{user().lastname + ', ' + user().firstname}}
                </div>
            </button>
        }

        <mat-menu
            #userMenu="matMenu"
            class="flex flex-col"
            xPosition="before">
            <button mat-menu-item
                    (click)="navigateTo('profile'); triggerMenu()"
            >
                @if (profile.icon) {
                    <mat-icon>{{profile.icon}}</mat-icon>
                }
                <div [class.text-start]="isMobile()" class="two-lines-ellipsis word-break-word">
                    {{ profile.tKey | translate }}
                </div>
            </button>
            <button mat-menu-item (click)="logout();"
            >
                <mat-icon>logout</mat-icon>
                <div [class.text-start]="isMobile()" class="two-lines-ellipsis word-break-word">
                    {{ translations.logout.title | translate }}
                </div>
            </button>
        </mat-menu>
    }

</div>